export const OurServices = [
  {
    id: 1,
    img: "",
    title: "Step 1: Share Files",
    description:
      "Share .pdf, .dwg, .dgn, and other CAD formats over a secure file submission link.",
  },
  {
    id: 2,
    img: "./4d-bim-black.svg",
    title: "Step 2: Project Assignment",
    description:
      "Our BIM experts will review your project and allocate it to the right team.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    title: "Step 3: BIM Delivery",
    description:
      "Our BIM Team delivers your project within the agreed timeline.",
  },
];

export const faq = [
  {
    id: 1,
    question: "What file formats are supported?",
    answer:
      "We create BIM models in various BIM formats such as Revit, Archicad, MicroStation, Tekla, IFC, and more.",
  },
  {
    id: 2,
    question: "How detailed will the BIM models be?",
    answer:
      "For 80% of requirements, LOD 350 is sufficient. However, for clients with additional specifications in pdf that they want to model into their BIM model, they can submit those files as well.",
  },
  {
    id: 3,
    question: "Is there a warranty period for the BIM models?",
    answer:
      "All completed models are managed via our internal file submission systems. We will allow unlimited modifications until 1-month post-delivery of BIM models",
  },
  {
    id: 4,
    question: "What files do I need to provide?",
    answer:
      "Provide us with the 2D CAD drawings, PDFs, and any other relevant files that you have to create the BIM models. For clash coordination and sequencing, please provide the architectural and strucutral BIM models.",
  },
];
